export default defineNuxtRouteMiddleware((to, _form) => {
  const user = useStrapiUser();

  if (!user.value) {
    abortNavigation();
    useCookie('redirect', { path: '/login' }).value = to.fullPath;

    return navigateTo('/login');
  }
})
